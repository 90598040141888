//
// Card
// --------------------------------------------------


// Header and footer

.card-header,
.card-footer {
  width: calc(100% - (var(--#{$prefix}card-spacer-x) * 2));
  margin: auto;
  padding: {
    right: 0;
    left: 0;
  }
}


// Header navs

.card-header-tabs,
.card-header-pills {
  margin: 0;
  .nav-item {
    margin-bottom: 0;
  }
  .nav-link.active {
    border-color: var(--#{$prefix}nav-tabs-link-active-border-color);
    background-color: var(--#{$prefix}nav-tabs-link-active-bg);
  }

  &.nav-tabs-alt {
    margin: {
      top: calc(var(--#{$prefix}card-cap-padding-y) * -.625);
      bottom: calc((var(--#{$prefix}card-cap-padding-y) + .0625rem) * -1);
    }
    border-bottom: 0;
  }
}
.card-header-pills .nav-link.active {
  background-color: var(--#{$prefix}nav-pills-link-active-bg);
}


// Hover effect

.card-hover:not(.bg-transparent) {
  transition: $card-transition;
  &:hover {
    transform: translateY(-.25rem);
    @include box-shadow($box-shadow-sm);
  }
  &.shadow-sm:hover {
    @include box-shadow($box-shadow !important);
  }
  &.shadow:hover {
    @include box-shadow($box-shadow-lg !important);
  }
  &.border-primary:hover {
    @include box-shadow($box-shadow-primary !important);
  }
}
.card-hover {
  --#{$prefix}card-icon-hover-color: #{$primary};

  .card-img-overlay {
    transition: opacity .3s ease-in-out;
    opacity: 0;
    z-index: 5;
  }
  .card-icon {
    transition: color .3s ease-in-out;
  }
  &:hover {
    .card-icon {
      color: var(--#{$prefix}card-icon-hover-color) !important;
    }
    .card-img-overlay {
      opacity: 1;
    }
  }
}

.card-hover-primary {
  transition: $card-transition;
  &.shadow-sm:hover,
  &.shadow:hover,
  &:hover {
    @include box-shadow($box-shadow-primary !important);
  }
  .card-title,
  .card-body,
  .text-muted,
  .text-dark,
  .text-nav,
  .text-primary,
  h1, .h1, h2, .h2, h3, .h3,
  h4, .h4, h5, .h5, h6, .h6 {
    transition: color .2s ease-in-out;
  }
  .border-top, .border-end,
  .border-bottom, .border-start {
    transition: border-color .2s ease-in-out;
  }
  .bg-secondary {
    transition: background-color .2s ease-in-out;
  }
  &:hover {
    border-color: var(--#{$prefix}primary) !important;
    background-color: var(--#{$prefix}primary) !important;
    .card-title { color: $white; }
    .card-body { color: rgba($white, .7); }
    .text-muted { color: rgba($white, .5) !important; }
    .text-primary { color: $white !important; }
    .border-top, .border-end,
    .border-bottom, .border-start {
      border-color: $border-light-color !important;
    }
    .bg-secondary {
      background-color: rgba($white, .05) !important;
    }
    h1, .h1, h2, .h2, h3, .h3,
    h4, .h4, h5, .h5, h6, .h6 {
      color: $white !important;
      > a { color: $white !important; }
    }
    .text-dark:not(.badge.bg-white) { color: $white !important; }
    .text-nav:not(.badge.bg-white) { color: $nav-link-dark-mode-color !important; }
  }
}

.card-hover-border {
  --#{$prefix}card-border-color: transparent;

  &:hover {
    --#{$prefix}card-border-color: #{$gray-400};
  }
}


// Portfolio card

.card-portfolio {
  border: 0;
  background-color: transparent;

  .card-img {
    @include border-radius(var(--#{$prefix}card-border-radius));
    transform: translateZ(0);
    overflow: hidden;

    > img {
      display: block;
      transition: transform .35s ease-in-out;
    }
  }

  .card-body {
    padding: {
      right: 0;
      left: 0;
    }
  }

  @include media-breakpoint-up(md) {
    .card-portfolio-meta {
      transition: opacity .35s ease-in-out;
      opacity: 0;
    }
  
    &:hover {
      .card-img > img {
        transform: scale(1.05);
      }
      .card-portfolio-meta {
        opacity: 1;
      }
    }
  }
}


// Cookies dialog

#cm.box {
  padding: 1.5rem;
  border-radius: .5rem;

  #c-ttl {
    color: #0A0B0C !important;
    font-weight: 600 !important;
  }

  #c-txt {
    color: $body-color;
    &, & > * {
      font-size: $font-size-sm;
      font-weight: $font-weight-base;
    }
  }
}


// Card flip effect

.flip-card {
  display: block;
  width: 264px;
  height: 264px;
  background-color: transparent;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  color: $gray-900;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.flip-card-front,
.flip-card-back {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: $spacer * 2;
  @include border-radius(2.5rem);
}

.flip-card-back {
  transform: rotateY(180deg);
}


// Card circle alignment

@include media-breakpoint-up(lg) {
  .card-circle {
    height: 1060px;

    .flip-card {
      &:nth-child(2) {
        right: 25%;
        transform: translateX(75%) translateY(25%);
      }
      &:nth-child(4) {
        right: 25%;
        transform: translateX(75%) translateY(-25%);
      }
      &:nth-child(6) {
        left: 25%;
        transform: translateX(-75%) translateY(-25%);
      }
      &:nth-child(8) {
        left: 25%;
        transform: translateX(-75%) translateY(25%);
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .card-circle-text,
  .card-circle .flip-card {
    position: static !important;
    transform: none !important;
  }

  .card-circle-text {
    max-width: 30.75rem !important;
    margin: 0 auto;
  }

  .card-circle {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: $spacer * 1.5;

    .flip-card {
      width: calc(33.333% - #{$spacer});
    }
  }

  .flip-card:hover .flip-card-inner {
    transform: none;
  }

  .flip-card-front,
  .flip-card-back {
    @include border-radius($border-radius-lg);
  }
}

@include media-breakpoint-down(md) {
  .card-circle {
    gap: $spacer;

    .flip-card {
      width: calc(50% - #{$spacer * .5});
    }
  }

  .flip-card-front,
  .flip-card-back {
    padding: $spacer;
  }
}

@include media-breakpoint-down(sm) {
  .flip-card {
    height: 164px;
  }

  .flip-card-front,
  .flip-card-back {
    padding: $spacer * .5;
  }
}


// Card hover-scale effect

.card-hover-scale {
  position: relative;
  overflow: hidden;

  &:hover {
    .card-hover-scale-img {
      transform: translateZ(0) scale(1.175);
    }
  }
}

.card-hover-scale-img {
  transition: transform .45s ease;
  will-change: transform;
}
