//
// Bpm features layout + "Pulse" animation effect
// --------------------------------------------------


.bpm-features {
  display: block;
  position: relative;
  max-width: 400px;
  height: 300px;

  @include media-breakpoint-up(md) {
    max-width: 548px;
    height: 364px;
  }
}

.bpm-feature {
  position: absolute;

  &:nth-child(2),
  &:nth-child(3) {
    left: 75%;
  }
  &:nth-child(5),
  &:nth-child(6) {
    right: 75%;
  }

  @include media-breakpoint-down(md) {
    &:nth-child(2),
    &:nth-child(3) {
      left: 65%;
    }
    &:nth-child(5),
    &:nth-child(6) {
      right: 65%;
    }
  }
}

.pulse {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 7.75rem;
  height: 7.75rem;
  padding: .75rem;
  border-radius: 50%;
  background-color: $primary;
  text-align: center;
  transform: translateY(-50%) translateX(-50%);
  box-shadow: 0 0 0 rgba($primary, 0.15);
  animation: pulse 3s infinite;
  z-index: 5;

  @include media-breakpoint-down(md) {
    width: 6rem;
    height: 6rem;
  }

  &::after {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    box-shadow: 0 0 0 rgba($primary, 0.15);
    animation: pulse-2 3s infinite;
    content: '';
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba($primary, 0.16);
  }
  70% {
    box-shadow: 0 0 0 3.5rem rgba($primary, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba($primary, 0);
  }
}

@keyframes pulse-2 {
  0% {
    box-shadow: 0 0 0 0 rgba($primary, 0.8);
  }
  70% {
    box-shadow: 0 0 0 1.5rem rgba($primary, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba($primary, 0);
  }
}
