//
// Tables
// --------------------------------------------------

.table {
  --#{$prefix}table-th-color: #{$table-th-color};
  --#{$prefix}table-dark-color: #{$body-dark-mode-color};
  &:not(.table-dark) {
    thead:not(.thead-dark) th,
    tbody th {
      color: var(--#{$prefix}table-th-color);
    }
  }
  &.table-dark td {
    color: var(--#{$prefix}table-dark-color);
  }
}
.table > :not(:last-child) > :last-child > * {
  border-bottom-color: darken($border-color, 12%);
}
.table.table-dark > :not(:last-child) > :last-child > * {
  border-bottom-color: rgba($white, .3);
}

// Remove highlight border color between thead, tbody and tfoot.
.table > :not(:first-child) {
  border-top: 0;
}


// Flat version

.table-flat tr {
  th, td {
    &:first-child { padding-left: 0; }
  }
  th, td {
    &:last-child { padding-right: 0; }
  }
}
